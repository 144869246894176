/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const FloorViewerContext = createContext();
export const FloorViewerProvider = ({ children }) => {
  const stageViewportRef = useRef(null);
  const floorStageRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isUpdateFloorModelVisible, setIsUpdateFloorModelVisible] = useState(false);
  const [selectedRackObject, setSelectedRackObject] = useState(null);
  const [rackModelData, setRackModelData] = useState(null);
  const [selectedFloorCabniteForTranf, setSelectedFloorCabniteForTranf] = useState(null);
  const handleWheel = (e) => {
    e.evt.preventDefault();
    const stage = e.target.getStage();
    const oldScale = scale;
    const scaleBy = e.evt.deltaY < 0 ? 1.05 : 0.95;
    const pointerPos = stage.getPointerPosition();
    let newScale = oldScale * scaleBy;
    // Set minimum and maximum scale limits
    const minScale = 0.1;
    const maxScale = 10;
    if (newScale < minScale) newScale = minScale;
    if (newScale > maxScale) newScale = maxScale;
    setScale(newScale);
    const newPos = {
      x: pointerPos.x - (pointerPos.x - position.x) * (newScale / oldScale),
      y: pointerPos.y - (pointerPos.y - position.y) * (newScale / oldScale),
    };
    setPosition(newPos);
  };
 
  return (
    <FloorViewerContext.Provider
      value={{
        selectedRackObject, 
        setSelectedRackObject,
        scale,
        position,
        handleWheel,
        setScale,
        stageViewportRef,
        floorStageRef,
        setPosition,
        isUpdateFloorModelVisible,
        setIsUpdateFloorModelVisible,
        selectedFloorCabniteForTranf,
        setSelectedFloorCabniteForTranf,
        rackModelData,
        setRackModelData
      }}
    >
      <div
        ref={stageViewportRef}
        className="viewer"
        id="network-floor-scean-viewport"
      >
        {children}
      </div>
    </FloorViewerContext.Provider>
  );
};

export const useFloorViewer = () => useContext(FloorViewerContext);
